/* eslint-disable import/no-cycle */
import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { Buffer } from 'buffer';
import { settingsActions } from '../slice/settingsSlice';
import errorToast from '../../../common/toast/errorToast';
import successToast from '../../../common/toast/successToast';

const clearCache = async () => {
  const username = process.env.REACT_APP_AUTH_USER;
  const password = process.env.REACT_APP_AUTH_PASS;

  // Encode username and password to base64
  const credentials = Buffer.from(`${username}:${password}`).toString('base64');

  const response = await axios.delete(
    `${process.env.REACT_APP_BE_URL}config/clear-cache`,
    {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    },
  );
  return response;
};

export default function* callClearCacheSaga() {
  try {
    yield call(clearCache);
    successToast('Success', 'You have successfully cleared cache.');
    yield put(settingsActions.clearCacheSuccess());
    yield put(settingsActions.closeClearCacheConfirmationModal());
  } catch (error) {
    console.error('callClearCacheSaga', error);
    errorToast('Oops', 'Something went wrong please try again later.');
    yield put(settingsActions.clearCacheError('error'));
  }
}
