import { createSlice } from '@reduxjs/toolkit';
import { SettingsState } from '../types';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../store/types';

const initialState: SettingsState = {
  clearCacheIsLoading: false,
  clearCacheIsSuccess: false,
  clearCacheError: { hasError: false, description: '' },
  clearCacheConfirmationModalIsOpen: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    clearCache(state) {
      state.clearCacheIsLoading = true;
      state.clearCacheError = { hasError: false, description: '' };
    },
    clearCacheSuccess(state) {
      state.clearCacheIsLoading = false;
      state.clearCacheIsSuccess = true;
    },
    clearCacheError(state, action) {
      state.clearCacheIsLoading = false;
      state.clearCacheError = { hasError: true, description: action.payload };
    },
    openClearCacheConfirmationModal(state) {
      state.clearCacheConfirmationModalIsOpen = true;
    },
    closeClearCacheConfirmationModal(state) {
      state.clearCacheConfirmationModalIsOpen = false;
    },
  },
});

// Actions
export const settingsActions = settingsSlice.actions;

// Selectors
export const selectClearCacheIsLoading = (state: RootState) =>
  state.settingsReducer.clearCacheIsLoading;
export const selectClearCacheIsSuccess = (state: RootState) =>
  state.settingsReducer.clearCacheIsSuccess;
export const selectClearCacheError = (state: RootState) =>
  state.settingsReducer.clearCacheError;
export const selectClearCacheConfirmationModalIsOpen = (state: RootState) =>
  state.settingsReducer.clearCacheConfirmationModalIsOpen;

// Reducer
export default settingsSlice.reducer;
