import React, { useState } from 'react';
import styled from 'styled-components';
import PageTitle from '../../../common/PageTitle';
import PageWrapper from '../../../common/PageWrapper';
import { useAppDispatch } from '../../../store/types';
import StyledButton from '../../../common/StyledButton';
import ConfirmationModal from '../../../common/ConfirmationModal';
import { settingsActions } from '../slice/settingsSlice';

const StyledLabel = styled.p`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #25334f;
  margin-bottom: 8px;
  opacity: 1;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export default function Settings() {
  const dispatch = useAppDispatch();
  const [isOpenClearConfirmationModal, setOpenClearConfirmationModal] =
    useState(false);

  return (
    <>
      <PageTitle title="Settings" />
      <PageWrapper>
        <StyledLabelContainer>
          <StyledLabel>Clear cache</StyledLabel>
        </StyledLabelContainer>
        <StyledButtonContainer>
          <StyledButton
            buttonText="Clear"
            isError
            onClick={() => setOpenClearConfirmationModal(true)}
          />
        </StyledButtonContainer>
      </PageWrapper>

      <ConfirmationModal
        open={isOpenClearConfirmationModal}
        handleClose={() => {
          setOpenClearConfirmationModal(false);
        }}
        isCancellationModal
        modalTitle="Are you sure?"
        modalDescription="Are you sure you want to clear all cache?"
        backButtonText="Cancel"
        backButtonAction={() => {
          setOpenClearConfirmationModal(false);
        }}
        continueButtonText="Confirm"
        continueButtonAction={() => {
          dispatch(settingsActions.clearCache());
          setOpenClearConfirmationModal(false);
        }}
      />
    </>
  );
}
