export enum MessageSortBy {
  ALL = 'all',
  READ = 'read',
  UNREAD = 'unread',
}

export enum FirebaseCollections {
  POSTS = 'posts',
  CATEGORIES = 'categories',
  AUTHORS = 'authors',
  POST_CONTENTS = 'post-contents',
}
